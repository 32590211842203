import { SET_DATA_TEAMS, SET_TEAMS } from "../constant/constantTeams";

let initialState = {
  dataListTeams: [],
};

export const teamsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TEAMS: {
      return { ...state, teams: payload };
    }
    case SET_DATA_TEAMS: {
      return { ...state, dataListTeams: payload };
    }
    default:
      return { ...state };
  }
};
