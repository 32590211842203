import { SET_DATA_ACCOUNT } from "../constant/constantAccount";
import { accountServ } from "../../Services/accountService";

const setDataListAccountSucdess = (successValue) => {
  return {
    type: SET_DATA_ACCOUNT,
    payload: successValue,
  };
};
export const setDataListAccount = () => {
  return (dispatch) => {
    accountServ.getDataAccount()
      .then(data => {
        dispatch(setDataListAccountSucdess(data));
      })
      .catch(error => {
        console.error("Error Fetching Data:", error);
      });
  };
};
