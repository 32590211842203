import { SET_DATA_TEAMS } from "../constant/constantTeams";
import { teamsServ } from "../../Services/teamsService";

const setDataListTeamsSucdess = (successValue) => {
  return {
    type: SET_DATA_TEAMS,
    payload: successValue,
  };
};
export const setDataListTeams = () => {
  return (dispatch) => {
    teamsServ.getDataTeams()
      .then(data => {
        dispatch(setDataListTeamsSucdess(data));
      })
      .catch(error => {
        console.error("Error Fetching Data:", error);
      });
  };
};