import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "antd";
//import { NavLink } from "react-router-dom";
import ObserverTable from "./ObserverTable";
import { setDataListObserver } from "../../Redux/actions/actionObserver";

//import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
const { Search } = Input;

export default function Observer() {
  const { dataListObserver } = useSelector((state) => state.observerReducer);
  const dispatch = useDispatch();
  const [dataSearch, setDataSearch] = useState("");
  const [filteredObserver, setFilteredObserver] = useState(dataListObserver); // Initialize with dataListAccount

  useEffect(() => {
    dispatch(setDataListObserver());
    setFilteredObserver(dataListObserver);
  }, [dispatch, dataListObserver]);

  useEffect(() => {
    if (dataSearch) {
      const filtered = dataListObserver.filter(
        (observer) =>
          observer.surName.toLowerCase().includes(dataSearch.toLowerCase()) ||
          observer.givenName.toLowerCase().includes(dataSearch.toLowerCase()) ||
          observer.englishName
            .toLowerCase()
            .includes(dataSearch.toLowerCase()) ||
          observer.code.toLowerCase().includes(dataSearch.toLowerCase())
      );
      setFilteredObserver(filtered);
    } else {
      setFilteredObserver(dataListObserver);
    }
  }, [dataSearch, dataListObserver]);

  const onSearchChange = (e) => {
    setDataSearch(e.target.value);
  };

  const downloadExcel = () => {
    // Tạo workbook mới
    const wb = XLSX.utils.book_new();

    // Chuyển dữ liệu sang worksheet
    const ws = XLSX.utils.json_to_sheet(
      dataListObserver.map((observer) => ({
        "Observer ID": observer.id,
        "UniCode": observer.uniCode,
        "Code": observer.code,
        "Status": observer.status,
        "Surname": observer.surName,
        "Given Name": observer.givenName,
        "English Name": observer.englishName,
        "Pronoun": observer.pronoun,
        "URL Profile Picture": observer.urlProfilePicture,
        "National": observer.national,
        "Passport ID Number": observer.passportIdNumber,
        "URL Passport ID Number Front": observer.urlPassportIdNumberFront,
        "URL Passport ID Number Back": observer.urlPassportIdNumberBack,
        "Date of birth": observer.dob,
        "Sex": observer.sex,
        "Gender": observer.gender,
        "university" : observer.university,
        "religion" : observer.religion,
        "specialReligiousCeremony": observer.specialReligiousCeremony,
        "tshirtSize" : observer.tshirtSize,
        "Email": observer.email,
        "Phone": observer.phone,
        "WhatsApp": observer.whatsapp,
        "Telegram": observer.telegram,
        "Line": observer.line,
        "Facebook": observer.facebook,
        "Instagram": observer.instagram,
        "Phone 1 Emergency": observer.phone1Emergency,
        "Phone 2 Emergency": observer.phone2Emergency,
        "Medical Requirement": observer.medicalRequirement,
        "Dietary Limit": observer.dietaryLimit,
        "Allergy": observer.allergy,
        "Vegan": observer.vegan,
        "Sea Sick": observer.seaSick,
        "Car Sick": observer.carSick,
        "Register Pickup service": observer.registerBus,
        "Time Arrival": observer.timeArrival,
        "Accommodation": observer.accommodation,
        "Hotel Name": observer.hotelName,
        "pricingPack": observer.pricingPack,
        "URL Consent Form": observer.urlConsentForm,
        "Is Submit": observer.isSubmit ? "Yes" : "No",
        "Created At": observer.createdAt,
        "Updated At": observer.updatedAt,
      }))
    );

    // Thêm worksheet vào workbook
    XLSX.utils.book_append_sheet(wb, ws, "Observers");

    // Tạo tệp Excel
    const exportFileName = "Observers.xlsx";
    XLSX.writeFile(wb, exportFileName);
  };
  const onSearchSubmit = (value) => {
    const searchValue = value.trim().toLowerCase();
    if (searchValue) {
      const filtered = dataListObserver.filter(
        (observer) =>
          observer.surName.toLowerCase().includes(searchValue) ||
          observer.givenName.toLowerCase().includes(searchValue) ||
          observer.englishName.toLowerCase().includes(searchValue) ||
          observer.code.toLowerCase().includes(searchValue)
      );
      setFilteredObserver(filtered);
    } else {
      setFilteredObserver(dataListObserver);
    }
  };

  return (
    <div className="container mx-auto">
      <h1 className="text-2xl">Manage Observer</h1>
      {/* <div>
        <NavLink to="/admin/observer/addnew">
          <Button>Add Observer</Button>
        </NavLink>
      </div> */}
      <button
        onClick={downloadExcel}
        className="py-2 px-4 bg-blue-500 text-white rounded"
      >
        Download Excel
      </button>
      <br/>
      <Search
        placeholder="ENTER SURNAME, GIVEN NAME OR ENGLISH NAME TO SEARCH"
        allowClear
        onChange={onSearchChange}
        onSearch={onSearchSubmit}
        style={{
          width: 600,
        }}
        className="py-2"
      />
      <ObserverTable dataListObserver={filteredObserver} />
    </div>
  );
}
