import { https } from "./configURL";

export const teamsServ = {
  editTeams: (id, data) => https.patch(`/team/${id}`, data),
  getInfo: (teamId) => https.get(`/team/${teamId}`),
  getDataTeams: () => {
    return https
      .get("/team/index")
      .then((response) => {
        if (response.data && Array.isArray(response.data)) {
          return response.data;
        } else {
          console.error("Unexpected response structure", response.data);
          throw new Error(
            "Unexpected response structure: " +
              JSON.stringify(response.data, null, 2)
          );
        }
      })
      .catch((error) => {
        console.error(
          "API call failed",
          error.response || error.message || error
        );
        throw error;
      });
  },
  deleteTeams: (id) => {
    return https
      .delete(`/team/${id}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(
          "Error deleting the team:",
          error.response || error.message || error
        );
        throw error; 
      });
  }, 
};
