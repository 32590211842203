import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "antd";
import TeamsTable from "./TeamsTable";
import { setDataListTeams } from "../../Redux/actions/actionTeams";

import * as XLSX from "xlsx";

const { Search } = Input;

export default function Teams() {
  const { dataListTeams } = useSelector((state) => state.teamsReducer);
  const dispatch = useDispatch();
  const [dataSearch, setDataSearch] = useState("");
  const [filteredTeams, setFilteredTeams] = useState([]);

  useEffect(() => {
    dispatch(setDataListTeams());
  }, [dispatch]);

  useEffect(() => {
    setFilteredTeams(dataListTeams); // Initialize filteredTeams with the full team list
  }, [dataListTeams]);

  useEffect(() => {
    // Filter the dataListTeams based on the team name whenever dataSearch changes
    if (dataSearch) {
      const filtered = dataListTeams.filter((team) =>
        team.team.name.toLowerCase().includes(dataSearch.toLowerCase())
      );
      setFilteredTeams(filtered);
    } else {
      setFilteredTeams(dataListTeams); // Reset to the full list when search is cleared
    }
  }, [dataSearch, dataListTeams]);

  const onSearchChange = (e) => {
    setDataSearch(e.target.value);
  };

  const onSearchSubmit = (value) => {
    if (value.trim()) {
      const searchValue = value.trim().toLowerCase();
      const filtered = dataListTeams.filter((team) =>
        team.team.name.toLowerCase().includes(searchValue)
      );
      setFilteredTeams(filtered);
    } else {
      setFilteredTeams(dataListTeams);
    }
  };
  const downloadExcel = () => {
    const wb = XLSX.utils.book_new();
    filteredTeams.forEach((teamData, index) => {
      const wsName = `Team ${index + 1} - ${teamData.team.name}`;
      const wsData = teamData.team.members.map(member => ({
        "Team Name": teamData.team.name,
        "Member ID": member.idMember,
        "Surname": member.surName,
        "Given Name": member.givenName,
        "English Name": member.englishName,
        "Pronoun": member.pronoun,
        "Url Profile Picture": member.urlProfilePicture,
        "National": member.national,
        "Passport ID Number": member.passportIdNumber,
        "URL Passport ID Number Front": member.urlPassportIdNumberFront,
        "URL Passport ID Number Back": member.urlPassportIdNumberBack,
        "Date of birth": member.dob,
        "Sex": member.sex,
        "Gender": member.gender,
        "Email": member.email,
        "Phone": member.phone,
        "WhatsApp": member.whatsapp,
        "Telegram": member.telegram,
        "Line": member.line,
        "Facebook": member.facebook,
        "Instagram": member.instagram,
        "Phone 1 Emergency": member.phone1Emergency,
        "Phone 2 Emergency": member.phone2Emergency,
        "Medical Requirement": member.medicalRequirement,
        "Dietary Limit": member.dietaryLimit,
        "Allergy": member.allergy,
        "Vegan": member.vegan,
        "Sea Sick": member.seaSick,
        "Car Sick": member.carSick,
        "Time Arrival": member.timeArrival,
        "Register Pickup service": member.registerBus,
        "Accommodation": member.accommodation,
        "URL Confirmation Letter": member.urlConfirmationLetter,
        "URL Consent Form": member.urlConsentForm,
        "Pricing Pack": member.pricingPack,
        "hotelName": member.hotelName,
        "tshirtSize": member.tshirtSize,
        "specialReligiousCeremony": member.specialReligiousCeremony,
        "religion": member.religion,
        "colloquialUniversity": member.colloquialUniversity,
        "program": member.program,
        "faculty" : member.faculty,
        "UniCode": member.uniCode
      }));
      const ws = XLSX.utils.json_to_sheet(wsData);
      XLSX.utils.book_append_sheet(wb, ws, wsName.slice(0, 31)); // Sheet names are limited to 31 characters
    });

    XLSX.writeFile(wb, "TeamsData.xlsx");
  };
  // const downloadExcel = () => {
  //   const wb = XLSX.utils.book_new(); // Tạo workbook mới
  //   let allData = []; // Mảng để chứa dữ liệu của tất cả các team

  //   // Lặp qua từng team và thêm dữ liệu của mỗi thành viên vào mảng allData
  //   filteredTeams.forEach((teamData) => {
  //     teamData.team.members.forEach((member) => {
  //       allData.push({
  //         "Team Name": teamData.team.name,
  //         "Member ID": member.idMember,
  //         Surname: member.surName,
  //         "Given Name": member.givenName,
  //         "English Name": member.englishName,
  //         Pronoun: member.pronoun,
  //         National: member.national,
  //         "Passport ID Number": member.passportIdNumber,
  //         DOB: member.dob,
  //         Gender: member.gender,
  //         Email: member.email,
  //         Phone: member.phone,
  //         WhatsApp: member.whatsapp,
  //         Telegram: member.telegram,
  //         Line: member.line,
  //         Facebook: member.facebook,
  //         Instagram: member.instagram,
  //         "Phone 1 Emergency": member.phone1Emergency,
  //         "Phone 2 Emergency": member.phone2Emergency,
  //         "Medical Condition": member.medicalCondition,
  //         "Medical Requirement": member.medicalRequirement,
  //         "Dietary Limit": member.dietaryLimit,
  //         Allergy: member.allergy,
  //         Vegan: member.vegan,
  //         "Sea Sick": member.seaSick,
  //         "Car Sick": member.carSick,
  //         "Time Arrival": member.timeArrival,
  //         "Register Bus": member.registerBus,
  //         Accommodation: member.accommodation,
  //         "URL Profile Picture": member.urlProfilePicture,
  //         "URL Passport ID Number Front": member.urlPassportIdNumberFront,
  //         "URL Passport ID Number Back": member.urlPassportIdNumberBack,
  //         "URL Consent Form": member.urlConsentForm,
  //         "Is Submit": member.isSubmit ? "Yes" : "No",
  //         Sex: member.sex,
  //         UniCode: member.uniCode,
  //       });
  //     });
  //   });

  //   const ws = XLSX.utils.json_to_sheet(allData);
  //   XLSX.utils.book_append_sheet(wb, ws, "All Teams"); 

  //   XLSX.writeFile(wb, "TeamsData.xlsx");
  // };

  return (
    <div className="container mx-auto">
      <h1 className="text-2xl">Manage Teams</h1>
      <button
        onClick={downloadExcel}
        className="py-2 px-4 bg-blue-500 text-white rounded"
      >
        Download Excel
      </button>
      <br />
      <Search
        placeholder="ENTER NAME OF TEAM TO SEARCH"
        allowClear
        onChange={onSearchChange}
        onSearch={onSearchSubmit}
        style={{ width: 600 }}
        className="py-2"
      />

      <TeamsTable dataListTeams={filteredTeams} />
    </div>
  );
}
