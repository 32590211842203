import React, { Fragment } from "react";
import { message, Popconfirm, Table, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setDataListTeams } from "../../Redux/actions/actionTeams";
import { teamsServ } from "../../Services/teamsService";

export default function TeamsTable({ dataListTeams }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const transformedData = dataListTeams.map((data, index) => {
    const { team, idTeam } = data;
    // Initialize default values for each column
    let advisor = 'N/A';
    let member01 = 'N/A';
    let member02 = 'N/A';
    let member03 = 'N/A';
    let teamEmail = team.idUser?.email || 'No Email';

    team.members.forEach(member => {
      switch (member.idMember) {
        case 'advisor':
          advisor = member.givenName;
          break;
        case 'candidate1':
          member01 = member.givenName;
          break;
        case 'candidate2':
          member02 = member.givenName;
          break;
        case 'candidate3':
          member03 = member.givenName;
          break;
        default:
          break;
      }
    });

    return {
      key: team._id || index,
      id: team._id,
      id2: idTeam,
      name: team.name,
      member01, 
      member02, 
      member03, 
      advisor,  
      createdAt: team.createdAt,
      status: team.status,
      email: teamEmail,
    };
   
  });

  const handleDelete = (id) => {
    teamsServ.deleteTeams(id)
      .then(() => {
        message.success("Team deleted successfully");
        dispatch(setDataListTeams()); 
      })
      .catch((err) => {
        message.error("Failed to delete team");
        console.error(err);
      });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id2",
      key: "id2",
    },
    {
      title: "Team ID",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Candidate1",
      dataIndex: "member01",
      key: "member01",
    },
    {
      title: "Candidate2",
      dataIndex: "member02",
      key: "member02",
    },
    {
      title: "Candidate3",
      dataIndex: "member03",
      key: "member03",
    },
    {
      title: "Advisor",
      dataIndex: "advisor",
      key: "advisor",
    },
  {
    title: "Email account user",
    dataIndex: "email", // This should match the new property in the transformedData
    key: "email",
  },
    {
      title: "Tình trạng",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: 'Pending', value: 'Pending' },
        { text: 'Unpaid', value: 'Unpaid' },
        { text: 'Paid', value: 'Paid' },
        // Add more statuses if there are any
      ],
      onFilter: (value, record) => record.status.includes(value),
      render: (status) => {
        let color;
        switch (status) {
          case "Pending":
            color = 'red';
            break;
          case "Unpaid":
            color = 'yellow';
            break;
          case "Paid":
            color = 'green';
            break;
          default:
            color = 'default';
        }
        return (
          <Button style={{ backgroundColor: color, borderColor: color, color: color === 'yellow' ? 'black' : 'white' }}>
            {status}
          </Button>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Fragment>
          <Popconfirm
            title="Are you sure to delete this team?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            {/* <span className="hover:cursor-pointer font-bold text-red-500">
              Delete
            </span> */}
          </Popconfirm>
          <span
            onClick={() => navigate(`/admin/teams/edit/${record.id}`)}
            style={{ marginLeft: 8 }}
            className="hover:cursor-pointer font-bold text-green-500"
          >
            Edit
          </span>
        </Fragment>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={transformedData}
      rowKey="key"
    />
  );
}
