import { https } from "./configURL";

export const accountServ = {
  editAccount: (id, data) => {
    return https.patch(`/user/${id}`, data)
      .then(response => response.data)
      .catch(error => { throw error; });
  },
  getInfo: (id) => https.get(`/user/${id}`),
  getDataAccount: () => {
    return https
      .get("/user/index")
      .then((response) => {
        if (
          response.data &&
          response.data.users && // Changed from response.data.account
          Array.isArray(response.data.users)
        ) {
          return response.data.users.map((obs) => ({ // Changed from response.data.account.map
            ...obs.user, // Adjusted to match the nested user object
            id: obs.idUser, // Adjusted to use idUser instead of idAccount
          }));
        } else {
          console.error("Unexpected response structure", response.data);
          throw new Error(
            "Unexpected response structure: " +
              JSON.stringify(response.data, null, 2)
          );
        }
      })
      .catch((error) => {
        console.error(
          "API call failed",
          error.response || error.message || error
        );
        throw error;
      });
  },
  deleteAccount: (userId) => {
    return https
      .delete(`/user/${userId}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(
          "Error deleting the account:",
          error.response || error.message || error
        );
        throw error; 
      });
  },
};
