import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Typography,
  message,
  Table,
} from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { teamsServ } from "../../Services/teamsService";
import { setDataListTeams } from "../../Redux/actions/actionTeams";

const { Option } = Select;
const { Title } = Typography;

const TeamsEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [teamDetails, setTeamDetails] = useState({
    members: [],
  });

  useEffect(() => {
    teamsServ
      .getInfo(id)
      .then((response) => {
        const { team } = response.data;
        let members = team.members.length ? team.members : Array(4).fill({}); // Ensure objects for advisor + 3 candidates if none exist

        // Sort members so that the advisor comes first, followed by candidates
        members = members.sort((a, b) => {
          if (a.idMember === "advisor") return -1;
          if (b.idMember === "advisor") return 1;
          return a.idMember.localeCompare(b.idMember); // Assuming idMember values are suitable for sorting candidates
        });

        setTeamDetails({ ...team, members });
        form.setFieldsValue({
          name: team.name,
          status: team.status,
          createdAt: moment(team.createdAt),
          updatedAt: moment(team.updatedAt),
          members,
        });
      })
      .catch((error) => {
        message.error(`Could not fetch team details: ${error.message}`);
      });
  }, [id, form]);

  const onFinish = () => {
    form.validateFields().then((values) => {
      const finalDataToUpdate = {
        ...values,
        createdAt: values.createdAt ? values.createdAt.format() : undefined,
        updatedAt: values.updatedAt ? values.updatedAt.format() : undefined,
      };
      teamsServ
        .editTeams(id, finalDataToUpdate)
        .then(() => {
          message.success("Team updated successfully");
          dispatch(setDataListTeams());
          navigate("/admin/teams");
        })
        .catch((error) => {
          message.error(`Update failed: ${error.message}`);
        });
    });
  };

  const columns = [
    {
      title: "Attribute",
      dataIndex: "attribute",
      key: "attribute",
    },
    ...teamDetails.members.map((member, index) => ({
      title:
        member.idMember === "advisor"
          ? "Advisor"
          : `Candidate ${member.idMember.slice(-1)}`,
      dataIndex: member.idMember,
      key: member.idMember,
      render: (text, record) => (
        <Form.Item name={["members", index, record.key]} noStyle>
          {record.key === "idMember" ||
          record.key === "isSubmit" ||
          record.key === "pricingPack" ||
          record.key === "idTeam" ? (
            <Input defaultValue={text} disabled />
          ) : (
            <Input defaultValue={text} />
          )}
        </Form.Item>
      ),
    })),
  ];
  // Hàm để chuyển đổi tên trường từ camelCase hoặc các trường hợp đặc biệt thành text có dấu cách
  const formatAttributeName = (name) => {
    // Kiểm tra trường hợp đặc biệt
    if (name === "dob") {
      return "Date of Birth";
    }

    // Chuyển đổi các ký tự đầu tiên thành chữ hoa, và thêm dấu cách trước mỗi chữ hoa cho các trường hợp còn lại
    return (
      name
        // Chèn một dấu cách trước mỗi chữ cái in hoa
        .replace(/([A-Z])/g, " $1")
        // Chuyển đổi ký tự đầu tiên thành chữ hoa
        .replace(/^./, (str) => str.toUpperCase())
        // Đặc biệt xử lý cho các trường hợp như IDMember thành ID Member
        .replace(/ID/, "ID ")
    );
  };
  const dataSource = teamDetails.members.reduce((acc, member) => {
    Object.keys(member).forEach((key) => {
      if (!acc.find((item) => item.key === key)) {
        acc.push({
          key,
          attribute: formatAttributeName(key), // Sử dụng hàm để chuyển đổi tên
          ...teamDetails.members.reduce(
            (memAcc, mem, idx) => ({
              ...memAcc,
              [mem.idMember]: mem[key] || "N/A",
            }),
            {}
          ),
        });
      }
    });
    return acc;
  }, []);
  const indexOfCandidate1 = teamDetails.members.findIndex(member => member.idMember === 'candidate1');

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      initialValues={teamDetails}
    >
      <Title level={2}>Edit Team</Title>
      <Form.Item>
        <Button type="primary" htmlType="submit" size="large">
          Update Team
        </Button>
      </Form.Item>
      <Form.Item name="name" label="Team Name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="status" label="Status" rules={[{ required: true }]}>
        <Select>
          <Option value="Pending">Pending</Option>
          <Option value="Unpaid">Unpaid</Option>
          <Option value="Paid">Paid</Option>
        </Select>
      </Form.Item>
      {indexOfCandidate1 !== -1 && (
        <Form.Item
          name={["members", indexOfCandidate1, "pricingPack"]}
          label="Pricing Pack"
          rules={[{ required: true, message: "Pricing pack is required" }]}
        >
          <Select>
            <Option value="pack1">Pack 1</Option>
            <Option value="pack2">Pack 2</Option>
          </Select>
        </Form.Item>
      )}

      <Form.Item name="createdAt" label="Created At">
        <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
      </Form.Item>
      <Form.Item name="updatedAt" label="Updated At">
        <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
      </Form.Item>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        bordered
      />
    </Form>
  );
};

export default TeamsEdit;
