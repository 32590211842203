import { SET_DATA_OBSERVER, SET_OBSERVER } from "../constant/constantObserver";

let initialState = {
  dataListObserver: [],
};

export const observerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_OBSERVER: {
      return { ...state, observer: payload };
    }
    case SET_DATA_OBSERVER: {
      return { ...state, dataListObserver: payload };
    }
    default:
      return { ...state };
  }
};
