import React, { Fragment } from "react";
import { message, Popconfirm, Table, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { observerServ } from "../../Services/observerService";
import { setDataListObserver } from "../../Redux/actions/actionObserver";
import { useDispatch} from "react-redux";
export default function ObserverTable({ dataListObserver }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const dataListObserver = useSelector(
  //   (state) => state.observerReducer.dataListObserver
  // );

  // useEffect(() => {
  //   dispatch(setDataListObserver());
  // }, [dispatch]);
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Observer ID",
      dataIndex: "code",
      key: "code",
    },  
    {
      title: "Uni Code",
      dataIndex: "uniCode",
      key: "uniCode",
    },   
    {
      title: "Sur Name",
      dataIndex: "surName",
      key: "surName",
    },
    {
      title: "Given Name",
      dataIndex: "givenName",
      key: "givenName",
    },
    {
      title: "English Name",
      dataIndex: "englishName",
      key: "name",
    },
    {
      title: "Phone number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email account user",
      dataIndex: ["idUser", "email"],
      key: "email",
    },
    {
      title: "Tình trạng",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: 'Pending', value: 'Pending' },
        { text: 'Unpaid', value: 'Unpaid' },
        { text: 'Paid', value: 'Paid' },
        // Add more statuses if there are any
      ],
      onFilter: (value, record) => record.status.includes(value),
      render: (status) => {
        let btnType = "";
        let style = {};

        switch (status) {
          case "Pending":
            btnType = "default";
            style = {
              backgroundColor: "red",
              color: "white",
              borderColor: "red",
            };
            break;
          case "Unpaid":
            btnType = "default";
            style = {
              backgroundColor: "yellow",
              color: "black",
              borderColor: "yellow",
            };
            break;
          case "Paid":
            btnType = "default";
            style = {
              backgroundColor: "green",
              color: "white",
              borderColor: "green",
            };
            break;
          default:
            btnType = "default";
            break;
        }

        return (
          <Button type={btnType} style={style}>
            {status}
          </Button>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, observer) => {
        return (
          <Fragment>
            <div className="space-x-2">
              <Popconfirm
                title="Are you sure to delete this observer?"
                onConfirm={() => {
                  // Use the correct ID based on your backend, e.g., `observer._id`
                  observerServ
                    .deleteObserver(observer._id)
                    .then((res) => {
                      message.success("Delete successful");
                      // Refresh the data by re-fetching the observer list
                      dispatch(setDataListObserver());
                    })
                    .catch((err) => {
                      const errorMessage =
                        err.response?.data?.message || err.message;
                      message.error(`Delete failed: ${errorMessage}`);
                      console.error("Delete Error:", err);
                    });
                }}
                okText="Yes"
                cancelText="No"
              >
                {/* <span className="hover:cursor-pointer font-bold text-red-500">
                  Delete
                </span> */}
              </Popconfirm>
              <span
                onClick={() => {
                  navigate(`/admin/observer/edit/${observer._id}`);
                }}
                className="hover:cursor-pointer font-bold text-green-500"
              >
                Edit
              </span>
            </div>
          </Fragment>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataListObserver}
      rowKey={(record) => record.id}
    />
  );
}
