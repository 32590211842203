import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "antd";
import AccountTable from "./AccountTable";
import { setDataListAccount } from "../../Redux/actions/actionAccount";

const { Search } = Input;

export default function Account() {
  const { dataListAccount } = useSelector((state) => state.accountReducer);
  const dispatch = useDispatch();
  const [dataSearch, setDataSearch] = useState("");
  const [filteredAccounts, setFilteredAccounts] = useState(dataListAccount); 
  
  useEffect(() => {
    dispatch(setDataListAccount());
    setFilteredAccounts(dataListAccount);
  }, [dispatch, dataListAccount]);

  useEffect(() => {
    if (dataSearch) {
      const filtered = dataListAccount.filter(account =>
        account.email.toLowerCase().includes(dataSearch.toLowerCase()) 
      );
      setFilteredAccounts(filtered);
    } else {
      setFilteredAccounts(dataListAccount);
    }
  }, [dataSearch, dataListAccount]);

  const onSearchChange = (e) => {
    setDataSearch(e.target.value);
  };

  const onSearchSubmit = (value) => {
    const searchValue = value.trim().toLowerCase();
    if (searchValue) {
      const filtered = dataListAccount.filter(account =>
        account.email.toLowerCase().includes(searchValue) 
      );
      setFilteredAccounts(filtered);
    } else {
      setFilteredAccounts(dataListAccount);
    }
  };

  return (
    <div className="container mx-auto">
      <h1 className="text-2xl">Manage Account</h1>
      <Search
        placeholder="ENTER EMAIL REGISTER TO SEARCH"
        allowClear
        onChange={onSearchChange}
        onSearch={onSearchSubmit}
        style={{ width: 600 }}
        className="py-2"
      />
      <AccountTable dataListAccount={filteredAccounts} />
    </div>
  );
}
