import React, { Fragment } from "react";
import { message, Popconfirm, Table} from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { accountServ } from "../../Services/accountService";
import { setDataListAccount } from "../../Redux/actions/actionAccount";

export default function AccountTable({ dataListAccount }) { // Accept the dataListAccount prop
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getStatusText = (status) => {
    switch (status) {
      case 0:
        return "Not Selected";
      case 1:
        return "Observer";
      case 2:
        return "Team";
      default:
        return "Unknown";
    }
  };

  // const handleDeleteAccount = (id) => {
  //   accountServ
  //     .deleteAccount(id)
  //     .then(() => {
  //       message.success("Delete successful");
  //       dispatch(setDataListAccount());
  //     })
  //     .catch((err) => {
  //       const errorMessage = err.response?.data?.message || err.message;
  //       message.error(`Delete failed: ${errorMessage}`);
  //       console.error("Delete Error:", err);
  //     });
  // };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Email Register Account",
      dataIndex: "email",
      key: "email",
    },  
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (status) => getStatusText(status), // Use the getStatusText function to render status text
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => getStatusText(status), // Hiển thị text tương ứng với mã trạng thái
      // Định nghĩa các lựa chọn lọc
      filters: [
        { text: "Not Selected", value: 0 },
        { text: "Observer", value: 1 },
        { text: "Team", value: 2 },
      ],
      // Hàm lọc dữ liệu dựa trên lựa chọn
      onFilter: (value, record) => record.status === value,
    },    
    {
      title: "Action",
      key: "action",
      render: (text, account) => {
        return (
          <Fragment>
            <div className="space-x-2">
              <Popconfirm
                title="Are you sure to delete this account?"
                onConfirm={() => {
                  accountServ
                    .deleteAccount(account._id)
                    .then((res) => {
                      message.success("Delete successful");
                      dispatch(setDataListAccount());
                    })
                    .catch((err) => {
                      const errorMessage =
                        err.response?.data?.message || err.message;
                      message.error(`Delete failed: ${errorMessage}`);
                      console.error("Delete Error:", err);
                    });
                }}
                okText="Yes"
                cancelText="No"
              >
                <span className="hover:cursor-pointer font-bold text-red-500">
                  Delete
                </span>
              </Popconfirm>
              <span
                onClick={() => {
                  navigate(`/admin/account/edit/${account._id}`);
                }}
                className="hover:cursor-pointer font-bold text-green-500"
              >
                View
              </span>
            </div>
          </Fragment>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataListAccount}
      rowKey={(record) => record.id}
    />
  );
}
