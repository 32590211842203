import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "antd/dist/antd.min.css";
import Admin from "./Admin/Admin";
import Spiner from "./Components/Spiner/Spiner";
import Observer from "./Admin/Observer/Observer";
import ObserverEdit from "./Admin/Observer/ObserverEdit";
import Teams from "./Admin/Teams/Teams";
import TeamsEdit from "./Admin/Teams/TeamsEdit";
import Account from "./Admin/Account/Account";
import AccountEdit from "./Admin/Account/AccountEdit";
import AlwaysTop from "./Components/ScrollTop/AlwaysTop";
import LoginPage from "./LoginPage/LoginPage";

const isAuthenticated = () => {
  const userInfo = sessionStorage.getItem("USER_INFO");
  return !!userInfo;
};

function App() {
  return (
    <div className="">
      <Spiner />
      <BrowserRouter>
        <AlwaysTop />
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route
            path="/admin/observer"
            element={
              isAuthenticated() ? (
                <Admin Component={Observer} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/admin/observer/edit/:id"
            element={
              isAuthenticated() ? (
                <Admin Component={ObserverEdit} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/admin/teams"
            element={
              isAuthenticated() ? (
                <Admin Component={Teams} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/admin/teams/edit/:id"
            element={
              isAuthenticated() ? (
                <Admin Component={TeamsEdit} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/admin/account"
            element={
              isAuthenticated() ? (
                <Admin Component={Account} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/admin/account/edit/:id"
            element={
              isAuthenticated() ? (
                <Admin Component={AccountEdit} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
