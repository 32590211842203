// import axios from "axios";

// export const BASE_URL = "https://imbc.onrender.com";
// export const TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NTdjMTY0YjU5NmJkNmQyZDRlNDg0MzgiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MDg1OTU3NTJ9.yotoDdGDO3Vwsqq98SKA4dH2xYhXKapCkGIq5lFqu4Q";

// export let https = axios.create({
//   baseURL: BASE_URL,
//   headers: {
//     'Authorization': `Bearer ${TOKEN}`,
//     'Content-Type': 'application/json',
//   },
// });


import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_URL;
export const TOKEN = process.env.REACT_APP_API_TOKEN;

export let https = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Authorization': `Bearer ${TOKEN}`,
    'Content-Type': 'application/json',
  },
});

