import { SET_DATA_OBSERVER } from "../constant/constantObserver";
import { observerServ } from "../../Services/observerService";

const setDataListObserverSucdess = (successValue) => {
  return {
    type: SET_DATA_OBSERVER,
    payload: successValue,
  };
};
export const setDataListObserver = () => {
  return (dispatch) => {
    observerServ.getDataObserver()
      .then(data => {
        dispatch(setDataListObserverSucdess(data));
      })
      .catch(error => {
        console.error("Error Fetching Data:", error);
      });
  };
};
