import React, { useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Typography,
  message,
} from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { observerServ } from "../../Services/observerService";
import { formItemLayout, tailFormItemLayout } from "../../Utilities/FormLayout";
import { setDataListObserver } from "../../Redux/actions/actionObserver";

const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography;

export const ObserverEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchObserverDetails = async () => {
      try {
        if (id) {
          const { data } = await observerServ.getInfo(id);
          const formData = {
            ...data.observer, // Assuming the response wraps the observer data in an observer object
            dob: data.observer.dob
              ? moment(data.observer.dob, "YYYY-MM-DD")
              : null,
            timeArrival: data.observer.timeArrival
              ? moment(data.observer.timeArrival, "YYYY-MM-DD HH:mm:ss")
              : null,
            accommodation: data.observer.accommodation
              ? moment(data.observer.accommodation, "YYYY-MM-DD HH:mm:ss")
              : null,
          };
          form.setFieldsValue(formData);
        }
      } catch (err) {
        message.error(`Could not fetch observer details: ${err.message}`);
      }
    };
    fetchObserverDetails();
  }, [id, form]);

  const onFinish = (values) => {
    //console.log("Received values of form:", values);
    // Convert moment objects to strings and prepare other data as needed
    const updatedValues = {
      ...values,
      dob: values.dob ? values.dob.format("YYYY-MM-DD") : undefined,
      timeArrival: values.timeArrival
        ? values.timeArrival.format("YYYY-MM-DD HH:mm:ss")
        : undefined,
      accommodation: values.accommodation
        ? values.accommodation.format("YYYY-MM-DD HH:mm:ss")
        : undefined,
    };
    console.log("Sending to API:", updatedValues); // Check final values sent to API

    const dataToSubmit = {
      observer: updatedValues,
    };

    observerServ
      .editObserver(id, dataToSubmit)
      .then(() => {
        message.success("Observer updated successfully");
        dispatch(setDataListObserver());
        navigate("/admin/observer");
      })
      .catch((err) => {
        const errorMessage = err.response
          ? err.response.data.message || err.response.data
          : err.message;
        message.error(`Update failed: ${errorMessage}`);
        console.error("Update Error Details:", err);
      });
  };

  const formFields = [
    {
      category: "Tình Trạng",
      fields: [
        {
          name: "status",
          label: "Status",
          message: "Vui lòng chọn tình trạng",
          component: (
            <Select>
              <Option value="Pending">Pending</Option>
              <Option value="Unpaid">Unpaid</Option>
              <Option value="Paid">Paid</Option>
            </Select>
          ),
        },
        {
          label: "Observer ID",
          name: "code",
          component: <Input />,
        },
        {
          label: "Uni Code",
          name: "uniCode",
          component: <Input />,
        },
      ],
    },
    {
      category: "Personal Information",
      fields: [
        {
          name: "surName",
          label: "Surname",
          message: "Surname is required",
          component: <Input />,
        },
        {
          name: "givenName",
          label: "Given Name",
          message: "Given name is required",
          component: <Input />,
        },
        {
          name: "englishName",
          label: "English Name",
          message: "English name is required",
          component: <Input />,
        },
        {
          name: "pronoun",
          label: "Pronoun",
          message: "Pronoun is required",
          component: (
            <Select>
              <Option value="he">He/Him</Option>
              <Option value="she">She/Her</Option>
              <Option value="they">They</Option>
            </Select>
          ),
        },
        {
          name: "urlProfilePicture",
          label: "Profile Picture URL",
          message: "Profile picture URL is required",
          component: <Input type="url" />,
          rules: [{ type: "url", message: "Must be a valid URL" }],
        },
        {
          name: "national",
          label: "Nationality",
          message: "Nationality is required",
          component: <Input />,
        },
        {
          name: "passportIdNumber",
          label: "Passport ID Number / Vietnam ID Number",
          message: "Passport/Vietnam ID number is required",
          component: <Input />,
        },
        {
          name: "urlPassportIdNumberFront",
          label: "Front ID Image URL",
          message: "Front ID image URL is required",
          component: <Input type="url" />,
          rules: [{ type: "url", message: "Must be a valid URL" }],
        },
        {
          name: "urlPassportIdNumberBack",
          label: "Back ID Image URL",
          message: "Back ID image URL is required",
          component: <Input type="url" />,
          rules: [{ type: "url", message: "Must be a valid URL" }],
        },
        {
          name: "dob",
          label: "Date of Birth",
          message: "Date of birth is required",
          component: <DatePicker />,
        },
        {
          name: "sex",
          label: "Sex",
          message: "Sex is required",
          component: (
            <Select>
              <Option value="male">Male</Option>
              <Option value="female">Female</Option>
              <Option value="other">Other</Option>
            </Select>
          ),
        },
        { name: "gender", label: "Gender (optional)", component: <Input /> },
        {
          name: "university",
          label: "University / School",
          message: "University / Schoolis required",
          component: <Input />,
        },
        {
          name: "religion",
          label: "Religion",
          message: "Religion required",
          component: <Input />,
        },
        {
          name: "specialReligiousCeremony",
          label: "Special Religious CeremonyY",
          message: "Religion required",
          component: <Input />,
        },
        {
          name: "tshirtSize",
          label: " IMBC2024 T-SHIRT SIZE",
          message: "Shirt required",
          component: (
            <Select>
              <Option value="xs">XS</Option>
              <Option value="s">S</Option>
              <Option value="m">M</Option>
              <Option value="xl">XL</Option>
              <Option value="xxl">XXL</Option>
              <Option value="3xl">3XL</Option>
              <Option value="4xl">4XL</Option>
              <Option value="5xl">5XL</Option>
              <Option value="oversize">Oversize</Option>
            </Select>
          ),
        },
      ],
    },
    {
      category: "Contact Information",
      fields: [
        {
          name: "email",
          label: "E-mail",
          message: "Please input your email!",
          component: <Input />,
          rules: [{ type: "email", message: "The input is not valid E-mail!" }],
        },
        {
          name: "phone",
          label: "Phone Number",
          message: "Please input your phone number!",
          component: <Input />,
          rules: [{ required: true, message: "Phone number is required!" }],
        },
        {
          name: "whatsapp",
          label: "Whatsapp Number",
          message: "Please input your Whatsapp number!",
          component: <Input />,
          rules: [{ required: true, message: "Whatsapp number is required!" }],
        },
        { name: "telegram", label: "Telegram", component: <Input /> },
        { name: "line", label: "Line", component: <Input /> },
        { name: "instagram", label: "Instagram Link", component: <Input /> },
        { name: "facebook", label: "Facebook", component: <Input /> },
      ],
    },
    {
      category: "Emergency Contact",
      fields: [
        {
          name: "phone1Emergency",
          label: "Emergency Phone 1",
          message: "Emergency phone number 1 is required!",
          component: <Input />,
          rules: [
            { required: true, message: "Emergency phone 1 is required!" },
          ],
        },
        {
          name: "phone2Emergency",
          label: "Emergency Phone 2",
          message: "Emergency phone number 2 is required!",
          component: <Input />,
          rules: [
            { required: true, message: "Emergency phone 2 is required!" },
          ],
        },
      ],
    },
    {
      category: "Medical Information",
      fields: [
        {
          name: "medicalRequirement",
          label: "Medical Requirement",
          message: "Please describe any medical requirements!",
          component: <TextArea rows={2} />,
        },
        {
          name: "dietaryLimit",
          label: "Dietary Limitations",
          message: "Please describe any dietary limitations!",
          component: <TextArea rows={2} />,
        },
        {
          name: "allergy",
          label: "Allergy Information",
          message: "Please describe any allergies!",
          component: <TextArea rows={2} />,
        },
        {
          name: "vegan",
          label: "Vegan/Vegetarian",
          message: "Please specify if you are vegan or vegetarian!",
          component: <TextArea rows={2} />,
        },
        {
          name: "seaSick",
          label: "Seasick Experience",
          message: "Please specify your experience with seasickness!",
          component: <TextArea rows={2} />,
        },
        {
          name: "carSick",
          label: "Carsick Experience",
          message: "Please specify your experience with carsickness!",
          component: <TextArea rows={2} />,
        },
      ],
    },
    {
      category: "TRANSPORTATION AND ACCOMMODATION",
      fields: [
        {
          name: "registerBus",
          label: "REGISTER FOR IMBC 2024 PICK UP SERVICE",
          message: "Please specify imbc2024 pick up service status!",
          component: (
            <Select>
              <Option value="yes">Yes</Option>
              <Option value="no">No</Option>
            </Select>
          ),
        },
        {
          name: "timeArrival",
          label: "Time of arrival in 20/06/2024",
          component: (
            <Select>
              <Option value="10:00">10:00 UTC +07:00</Option>
              <Option value="17:00">17:00 UTC +07:00</Option>
            </Select>
          ),
        },
        {
          name: "accommodation",
          label: "Accommodation",
          component: (
            <Select>
              <Option value="imbc">
                Register for IMBC2024 accommodation service
              </Option>
              <Option value="other">Staying at the another hotel</Option>
            </Select>
          ),
        },
        {
          name: "hotelName",
          label: "NAME AND ADDRESS OF HOTEL",
          component: <Input />,
        },
      ],
    },
    {
      category: "Pricing",
      fields: [
        {
          name: "pricingPack",
          label: "Pricing Pack",
          message: "Pricing Pack URL is required",
          component: (
            <Select>
								<Option value="pack1">Pack 1</Option>
								<Option value="pack2">Pack 2</Option>
            </Select>
          ),
        },
      ],
    },
    {
      category: "Agreements",
      fields: [
        {
          name: "urlConsentForm",
          label: "Consent Form URL",
          message: "Consent form URL is required",
          component: <Input type="url" />,
          rules: [{ type: "url", message: "Must be a valid URL" }],
        },
      ],
    },
  ];

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="editObserver"
      onFinish={onFinish}
      initialValues={{ sex: "Choose sex" }}
      scrollToFirstError
      layout="vertical"
      labelAlign="left"
      labelWrap
      wrapperCol={{ flex: 1 }}
      colon={false}
    >
      <div className="text-left">
        <Form.Item
          {...tailFormItemLayout}
          wrapperCol={{
            xs: {
              span: 24,
              offset: 0,
            },
            sm: {
              span: 16,
              offset: 0,
            },
          }}
        >
          <Button type="primary" htmlType="submit" size="large">
            Update observer
          </Button>
        </Form.Item>
        {formFields.map((section, index) => (
          <React.Fragment key={index}>
            <Title level={2} className="text-left">
              {section.category}
            </Title>
            {section.fields.map((field, fieldIndex) => (
              <Form.Item
                key={fieldIndex}
                name={field.name}
                label={field.label}
                rules={[
                  {
                    required: "required" in field ? field.required : false,
                    message: field.message,
                  },
                  ...(field.rules || []),
                ]}
              >
                {field.component}
              </Form.Item>
            ))}
          </React.Fragment>
        ))}
      </div>
    </Form>
  );
};

export default ObserverEdit;
