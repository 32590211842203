import { https } from "./configURL";

export const observerServ = {
  editObserver: (id, data) => {
    return https.patch(`/observer/${id}`, data)
      .then(response => response.data)
      .catch(error => { throw error; });
  },
  getInfo: (id) => https.get(`/observer/${id}`),
  getDataObserver: () => {
    return https
      .get("/observer/index")
      .then((response) => {
        if (
          response.data &&
          response.data.observers &&
          Array.isArray(response.data.observers)
        ) {
          return response.data.observers.map((obs) => ({
            ...obs.observer,
            id: obs.idObserver,
          }));
        } else {
          console.error("Unexpected response structure", response.data);
          throw new Error(
            "Unexpected response structure: " +
              JSON.stringify(response.data, null, 2)
          );
        }
      })
      .catch((error) => {
        console.error(
          "API call failed",
          error.response || error.message || error
        );
        throw error;
      });
  },
  deleteObserver: (observerId) => {
    return https
      .delete(`/observer/${observerId}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(
          "Error deleting the observer:",
          error.response || error.message || error
        );
        throw error; 
      });
  },
};
