import React, { useEffect, useState } from "react";
import { Form, Input, Select, Typography, message } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { accountServ } from "../../Services/accountService";
import { setDataListAccount } from "../../Redux/actions/actionAccount";

const { Option } = Select;
const { Title } = Typography;

const AccountEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [members, setMembers] = useState([]); // State to hold member users
  const [advisor, setAdvisor] = useState(null); // State to hold the single advisor user

  useEffect(() => {
    const fetchAccountDetails = async () => {
      try {
        if (id) {
          const { data } = await accountServ.getInfo(id);
          const formData = {
            email: data.user.email,
            status: data.user.status,
          };
          form.setFieldsValue(formData);
          setMembers(data.users.filter(user => user.role === "member")); // Filter and set member users
          const advisorData = data.users.find(user => user.role === "advisor"); // Find the advisor
          setAdvisor(advisorData); // Set the advisor
        }
      } catch (err) {
        // Handle error
      }
    };
    fetchAccountDetails();
  }, [id, form]);

  const onFinish = (values) => {
    console.log("Received values of form:", values);
    // You would need to adjust how you handle the submission to include member data
    accountServ
      .editAccount(id, values)
      .then(() => {
        message.success("Account updated successfully");
        dispatch(setDataListAccount());
        navigate("/admin/account");
      })
      .catch((err) => {
        const errorMessage = err.response
          ? err.response.data.message || err.response.data
          : err.message;
        message.error(`Update failed: ${errorMessage}`);
        console.error("Update Error Details:", err);
      });
  };

  return (
    <Form form={form} name="editAccount" onFinish={onFinish} scrollToFirstError>
      <Title level={2}>Account Details</Title>

      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
      >
        <Input disabled />
      </Form.Item>

      <Form.Item
        name="status"
        label="Status"
        rules={[{ required: true, message: "Please select a status!" }]}
      >
        <Select placeholder="Select a status" disabled={true} >
          <Option value={0}>0. Not Selected</Option>
          <Option value={1}>1. Observer</Option>
          <Option value={2}>2. Team</Option>
        </Select>
      </Form.Item>

      {/* Dynamic Form.Item components for members' emails and statuses */}
      {members.map((member, index) => (
        <React.Fragment key={member._id}>
          <Form.Item
            name={`members[${index}].email`}
            label={`Email member ${index + 1}`}
            initialValue={member.email}
          >
            <Input disabled />
          </Form.Item>
        </React.Fragment>
      ))}
      <Form.Item>
      {advisor && (
        <Form.Item
          name="advisor.email"
          label="Email Advisor"
          initialValue={advisor.email}
        >
          <Input disabled />
        </Form.Item>
      )}
        {/* <Button type="primary" htmlType="submit" size="large">
          Update Account
        </Button> */}
      </Form.Item>
    </Form>
  );
};

export default AccountEdit;
