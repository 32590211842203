import React, { useState } from "react";
import {
  UserOutlined,
  TeamOutlined,
  UserAddOutlined,
  LogoutOutlined
} from "@ant-design/icons";
import { Layout, Menu, Button } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
const { Content, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem(<NavLink to="/admin/observer">Observer</NavLink>, "sub1", <UserOutlined />),
  getItem(<NavLink to="/admin/teams">Team</NavLink>, "sub2", <TeamOutlined />),
  getItem(<NavLink to="/admin/account">Account</NavLink>, "sub3", <UserAddOutlined />),

];
export default function Admin({ Component }) {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate(); // Hook để chuyển hướng

  // Hàm xử lý khi nhấn logout
  const handleLogout = () => {
    sessionStorage.clear(); // Xóa session storage
    navigate("/"); // Chuyển hướng người dùng về trang login
  };
  return (
    <div>
      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <NavLink to="/">
            <img src="../imbc.svg" className=" p-1" alt=""/>
          </NavLink>
          <Menu
            theme="dark"
            defaultSelectedKeys={["1"]}
            mode="inline"
            items={items}
          />
                    <Button 
            type="primary" 
            icon={<LogoutOutlined />} 
            onClick={handleLogout}
            style={{ margin: '16px' }} // Thêm chút cách biệt cho nút logout
          >
            Logout
          </Button>
        </Sider>
        <Layout className="site-layout">
          <Content
            style={{
              margin: "0 16px",
            }}
          >
            <div
              className="site-layout-background"
              style={{
                padding: 24,
                minHeight: 360,
              }}
            >
              <Component />
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
