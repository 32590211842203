import { combineReducers } from "redux";
import { observerReducer } from "./reducerObserver";
import { teamsReducer } from "./reducerTeams";
import { accountReducer } from "./reducerAccount";
import { spinerReducer } from "./reducerSpiner";
export let rootReducer = combineReducers({
  observerReducer,
  teamsReducer,
  spinerReducer,
  accountReducer
});
