import { SET_DATA_ACCOUNT, SET_ACCOUNT } from "../constant/constantAccount";

let initialState = {
  dataListAccount: [],
};

export const accountReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ACCOUNT: {
      return { ...state, account: payload };
    }
    case SET_DATA_ACCOUNT: {
      return { ...state, dataListAccount: payload };
    }
    default:
      return { ...state };
  }
};
